<template>
	<div>
		<a-spin :spinning="loading">

			<div class="mt40 flex alcenter">
				<span class="ft20 ftw600 cl-main">我的商铺({{ mendians.length }})</span>
				<span class="ft14 cl-notice ml10">每个商铺为独立运营的，会员是不通用的。每个商铺可以邀请合伙人一起管理</span>
			</div>
			<div class="mt30 mendian-list" style="grid-row-gap: 0px;">
				<div v-for="(item, index) in mendians" style="margin-top: 20px;" @click="manageAct(item)"
					:class="{ dis: item.shop == null }" class="mendian-item flex space">
					<div>
						<div class="flex alcenter mt10">
							<div class=" ft20 ftw600 cl-main text-over4">
								{{ item.shop == null ? '该门店已注销' : item.shop.name }}
							</div>
							<div class="ft14 ftw400 cl-notice ml5 text-over4">
								({{ item.shop == null ? '' : item.shop.application == null ? '' :
									item.shop.application.name }})
							</div>
						</div>
						<div class="mt12 ft12 cl-info">开通时间：{{ item.add_time_format }}</div>
					</div>
					<div class="tag" v-if="item.role_id == 1">创始人</div>
					<div class="tag" v-if="item.role_id == 2">合伙人</div>
				</div>
			</div>

			<div class="mt20 ft20 ftw600 cl-main">试用应用({{ applications.length }})</div>

			<div class="mt20 flex space">
				<div class="mt20 mendian-list">

					<div v-for="(item, index) in applications" class="mendian-item flex space">
						<div class="overlay" @click="isauthorized(item)" v-if="item.status == 0 && item.is_beta == 0">

						</div>
						<div class="ft14 ftw400 cl-notice ml5 text-over4">
							<img v-if="item.logo != null" width="80" height="80" :src="item.logo" />
						</div>
						<div>
							<div class="" style="width: 250px">
								<div class="mt10 ft18 ftw600 cl-main">{{ item.name }} <span class="ml10"
										v-if="item.status == 0">{{ item.is_beta == 1 ? '(内测中)' : '(正在开发中)' }}</span></div>
								<div class="mt16 ft12 cl-info">{{ item.scene }}</div>
							</div>
						</div>
						<div v-if="item.is_authorized == 0">
							<div class="tagApp mt10 ">免费试用7天</div>
							<a-button class=" mt10 " @click="addMendianApp(item)" type="primary" ghost>免费试用</a-button>
						</div>
						<div v-else v-if="item.is_authorized == 1 || item.is_beta == 1">
							<a-button class=" mt20 " @click="addMendianApp(item)" type="primary" ghost>创建店铺</a-button>
						</div>
					</div>
					<div class="mendian-item">
						<div class="text-center mt16 ft18 cl-main ftw500">更多解决方案正在研发中</div>
						<div class="mt10 cl-notice ft12 text-center">敬请期待…</div>
					</div>
				</div>
			</div>

		</a-spin>

		<a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
			<template slot="footer">
				<a-button key="back" @click="handlePwdCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
					确认
				</a-button>
			</template>
			<a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="输入密码">
					<a-input type="password" v-model="pwd.pwd" />
				</a-form-model-item>
				<a-form-model-item label="再次确认">
					<a-input type="password" v-model="pwd.repwd" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal title="创建商铺" :visible="showForm" @ok="handleOkApp" @cancel="handleCancelApp"
			:confirm-loading="confirmLoading" width="400px">
			<template slot="footer">
				<a-button key="back" @click="handleCancelApp">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="handleOkApp">
					登录
				</a-button>
			</template>
			<div class="pd20_40">
				<a-input class="woyaou-input" placeholder="输入门店名称" prefix="店铺名" v-model="form.name" />
			</div>
		</a-modal>

		<a-modal title="试用商铺" :visible="syshowForm" @ok="syhandleOk" @cancel="syhandleCancel"
			:confirm-loading="syconfirmLoading" width="400px">
			<template slot="footer">
				<a-button key="back" @click="syhandleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="syhandleOk">
					去登录
				</a-button>
			</template>
			<div class="pd20_40">
				<a-input class="woyaou-input" placeholder="" prefix="试用账号" v-model="form.syuser" />
				<a-input style="margin-top: 10px;" class="woyaou-input" placeholder="" prefix="试用密码"
					v-model="form.sypassword" />
			</div>
		</a-modal>

	</div>
</template>

<script>
export default {

	data() {
		return {
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			loading: false,
			showPwd: false,
			pwd: {
				pwd: '',
				repwd: '',
			},
			pwdloading: false,
			account: {
				mobile: '',
				username: '',
				face: null,
			},
			mendians: [],
			syshowForm: false,
			showForm: false,
			syconfirmLoading: false,
			loadingApp: false,
			confirmLoading: false,
			account_id: 0,
			app_id: 0,
			search: {
				type: 1,
			},
			form: {
				name: '',
			},
			type_list: [],
			base_applications: [],
			applications: [],
			appnum: 0,
		}
	},

	created() {
		this.getLists();
		this.getListsApp();
	},
	methods: {
		isauthorized(item) {

			if (item.is_authorized != 1) {
				this.$message.error('请联系终端管理员增加应用授权')
			}
		},
		getListsApp() {
			if (this.loadingApp == true) return;
			this.loadingApp = true;
			this.$http.api('platform/account/showApp', {
				type: this.search.type
			}).then(res => {
				this.type_list = res.type_list;
				this.base_applications = res.base_applications;
				this.applications = res.applications;
				this.account_id = res.account_id;
				this.loadingApp = false;
			}).catch(res => {
				console.log(res);
				this.loadingApp = false;
			})
		},

		selectIt(id) {
			this.search.type = id;
			this.getLists();
		},
		syhandleOk() {
			window.open(this.form.syaddress);
		},
		syhandleCancel() {
			this.syshowForm = false;
		},
		handleOkApp() {
			if (this.confirmLoading == true) return;
			this.confirmLoading = true;
			this.$http.api('platform/account/createShop', {
				app_id: this.app_id,
				name: this.form.name,
			}).then(res => {
				
				this.confirmLoading = false;
				this.showForm = false;
				this.$message.success('创建商铺成功', 1, () => {
					window.open(res.base_url + 'auth?shop_token=' + encodeURIComponent(res.shop_token));
				})
			}).catch(res => {
				this.confirmLoading = false;
			})
		},
		handleCancelApp() {
			this.showForm = false;
		},
		addMendianApp(item) {
			console.log(item);
			// v-if="item.status == 0 && item.is_beta == 0"
			if (item.status == 0 && item.is_beta == 0)  return
			this.app_id = item.app_id;
			this.form = {
				name: '',
			}

			if (item.is_authorized != 1) {
				this.syshowForm = true;

				this.form.syuser = item.demo_account
				this.form.sypassword = item.demo_pwd
				this.form.syaddress = item.demo_address

			} else {
				this.showForm = true;
			}


		},

		handlePwdCancel() {
			this.showPwd = false;
		},
		handlePwdOk() {
			if (this.pwdloading == true) return;
			this.pwdloading = true;
			this.$http.api('platform/account/editPwd', {
				pwd: this.pwd.pwd,
				repwd: this.pwd.repwd
			}).then(res => {
				this.pwdloading = false;
				this.$message.success('密码设置成功');
				this.showPwd = false;
			}).catch(res => {
				this.pwdloading = false;
			});
		},
		getLists() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('platform/account/home').then(res => {
				this.account = res.account;
				this.mendians = res.mendian;
				if (this.account.password == null || this.account.password == '') {
					this.showPwd = true;
				}
			}).catch(res => {
				console.log(res);
			}).finally(() => {
				this.loading = false;
			})
		},

		manageAct(item) {
			// console.log(item); 
			// console.log(item.shop.application.development_address); return
			if (item.shop == null) {
				this.$message.error('该门店已注销');
				return false;
			}
			
			let base_url = process.env.NODE_ENV == 'production' ? item.shop.application.development_address : 'http://localhost:9190/beautiful#/'
			if (item.shop.application.name =='易企高校') {
				window.open(base_url + 'auth?shop_token=' + encodeURIComponent(item.shop_token)+'&type='+1);
			}else {
				window.open(base_url + 'auth?shop_token=' + encodeURIComponent(item.shop_token));
			}
			
			
			
		},
		handleOk() {
			this.showForm = false;
		},
		handleCancel() {
			this.showForm = false;
		},
		addMendian() {
			// this.showForm = true;
			this.$router.push('/mendian/create');
		},

	}
}
</script>

<style scoped>
.mendian-list {
	display: grid;
	grid-template-columns: repeat(3, 380px);
	/* grid-template-rows: repeat(3,100px);  */
	grid-column-gap: 160px;
	grid-row-gap: 40px;
}

.mendian-item {
	width: 520px;
	height: 120px;
	background: #FFFFFF;
	border-radius: 10px;
	/*border-left: 8px solid #EBEDF5;*/
	padding: 20px 16px;
	cursor: pointer;
	transition: all 0.3s;
	position: relative;
}

.mendian-item.dis {
	opacity: 0.5;
}

.mendian-item .tag {
	width: 52px;
	height: 20px;
	border-radius: 10px;
	border: 1px solid #4772FF;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
	color: #4772FF;
}

.mendian-item .tagApp {
	width: 90px;
	height: 20px;
	border-radius: 10px;
	border: 1px solid;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
	background-color: #4772FF;
	color: white;
}

.mendian-item:hover {
	transform: translateY(-10px);
}

.mendian-item.add {
	border: 1px dashed #4772FF;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
}
</style>